import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Rating from "./sub-components/ProductRating";
import {getHexaColorFromName, slugify} from "../../helpers/product"
import {useTranslation} from "react-i18next";
import ReactGA from "react-ga4";

const ProductGridListSingle = ({
  product,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass,
  avalaibleColors,
  partnerName
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();
  const { t } = useTranslation()

  const getUniqueColors = (declinations) => {
    let colors = []
    if (declinations && declinations.length > 0){
      declinations.map(declination => {
        colors.push(declination.color)
      })
      const filteredArray = colors.filter(function(ele , pos){
        return colors.indexOf(ele) == pos;
      })
      return filteredArray
    }

    return colors
  }

  const finalProductPrice = product.price;
  const discountedPrice = product.bestPrice;
  const finalDiscountedPrice = +(
      discountedPrice * currency.currencyRate
  ).toFixed(2);

  const discount = product.amount

  return (
    <Fragment>
      <div
        className={`col-xl-4 col-sm-6 ${
          sliderClassName ? sliderClassName : ""
        }`}
      >
        <div
          className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
        >
          <div className="product-img"
               onClick={() => {
                ReactGA.event({
                  category: 'Affiliate',
                  action: partnerName + ' Redirect to ' + product.name
                });
            }
          }>
            <a target="_blank" href={"https://app.therunningcollective.fr" + "/product/" + product.shortURL + "&utm_source=" +partnerName + "&utm_medium=affiliate" + "&utm_campaign=produit" + "&source=" + partnerName}>
              <img
                  className="default-img"
                  src={product.firstImageUrl}
                  alt=""
              />
            </a>
            {discount > 0 && discount !== 100 ? (
              <div className="product-img-badges">
                {discount > 0 && discount !== 100 ? (
                  <span className="purple">-{discount}%</span>
                ) : null}
              </div>
            ) : (
              ""
            )}
            {product.soonOutOfStock && !product.availableSoon? (
                  <div className="product-img-badges">
                    <span className="purple" style={{backgroundColor : 'orange', fontSize: "11px", color : 'white',
                        marginTop : (discount > 0  && discount !== 100) ? "25px" : 0}}>Dernières tailles</span>                  
                  </div>
              ) : (
                  ""
              )}

              {product.outOfStock && !product.availableSoon? (
                  <div className="product-img-badges">
                    <span className="purple" style={{backgroundColor : 'red', fontSize: "11px", color : 'white'}}>Rupture de stock</span>                
                  </div>
              ) : (
                  ""
              )} 

            {product.availableSoon ?
                <div className="product-img-badges">
                  <span className="purple" style={{backgroundColor : 'green'}}>Lancement produit 🚀</span>
                </div>
                : null }

            <div className="product-action">
              <div className="pro-same-action pro-cart">
                
              </div>
            </div>
          </div>
          <div className="product-content-2">
            <div
                className={`title-price-wrap-2`}
                onClick={() => {
                  ReactGA.event({
                    category: 'Affiliate',
                    action: partnerName + ' Redirect to ' + product.name
                  });
                }}
            >
              <h3>
                <a target="_blank" href={"https://app.therunningcollective.fr" + "/product/" + product.shortURL + "&utm_source=" +partnerName + "&utm_medium=affiliate" + "&utm_campaign=produit&source=" + partnerName}>
                  {product.name}
                </a>
              </h3>
              <div>
                <a target="_blank" href={"https://app.therunningcollective.fr" + "/product/" + product.shortURL + "&utm_source=" +partnerName + "&utm_medium=affiliate" + "&utm_campaign=produit&source=" + partnerName}>
                  <span style={{color: "grey", fontWeight: 500, fontSize: "13px"}}>{t(product.gender.toLowerCase())}</span>
                </a>
              </div>
              <div className="price-2">
                {discount > 0 && discount !== 100 ? (
                    <Fragment>
                     <span style={{fontWeight: '600'}}>
                       {finalDiscountedPrice + currency.currencySymbol}
                     </span>
                      <span className="old" style={{color: 'grey', fontSize: '14px', fontWeight: '600'}}>
                       {finalProductPrice + currency.currencySymbol}
                     </span>
                    </Fragment>
                ) : (
                    <span style={{fontWeight: '600'}}>
                       {finalProductPrice + currency.currencySymbol}
                    </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="shop-list-wrap mb-30">
          <div className="row">
            <div className="col-xl-4 col-md-5 col-sm-6">
              <div className="product-list-image-wrap">
                <div className="product-img">
                <a target="_blank" href={"https://app.therunningcollective.fr" + "/product/" + product.shortURL + "&utm_source=" +partnerName + "&utm_medium=affiliate" + "&utm_campaign=produit&source=" + partnerName}>
                    <img
                      className="default-img img-fluid"
                      src={product.firstImageUrl}
                      alt=""
                    />
                  </a>
                  {discount > 0 && discount !== 100 ? (
                    <div className="product-img-badges">
                      {discount > 0 && discount !== 100 ? (
                        <span className="purple">-{discount}%</span>
                      ) : (
                        ""
                      )}
                      {product.new ? <span className="purple">New</span> : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7 col-sm-6">
              <div className="shop-list-content">
                <h3>
                  <a target="_blank" href={"https://app.therunningcollective.fr" + "/product/" + product.shortURL + "&utm_source=" +partnerName + "&utm_medium=affiliate" + "&utm_campaign=produit&source=" + partnerName}>
                    {product.name}
                  </a>
                </h3>
                <div className="product-list-price">
                  {discount > 0 && discount !== 100 ? (
                    <Fragment>
                      <span>
                        {finalDiscountedPrice + currency.currencySymbol}
                      </span>{" "}
                      <span className="old">
                        {finalProductPrice + currency.currencySymbol}
                      </span>
                    </Fragment>
                  ) : (
                    <span>{finalProductPrice + currency.currencySymbol} </span>
                  )}
                </div>
                {product.rating && product.rating > 0 ? (
                  <div className="rating-review">
                    <div className="product-list-rating">
                      <Rating ratingValue={product.rating} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {product.shortDescription ? (
                  <p>{product.shortDescription}</p>
                ) : (
                  ""
                )}

                <div className="shop-list-actions d-flex align-items-center">
                  <div className="shop-list-btn btn-hover">
                    {product.affiliateLink ? (
                      <a
                        target="_blank" href={product.affiliateLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        Buy now{" "}
                      </a>
                    ) : product.variation && product.variation.length >= 1 ? (
                      <Link
                        to={`${"https://app.therunningcollective.fr"}/product/${product.id}`}
                      >
                        Select Option
                      </Link>
                    ) : product.stock && product.stock > 0 ? (
                      <button
                        onClick={() => addToCart(product, addToast)}
                        className={
                          cartItem !== undefined && cartItem.quantity > 0
                            ? "active"
                            : ""
                        }
                        disabled={
                          cartItem !== undefined && cartItem.quantity > 0
                        }
                        title={
                          cartItem !== undefined
                            ? "Added to cart"
                            : "Add to cart"
                        }
                      >
                        {" "}
                        <i className="pe-7s-cart"></i>{" "}
                        {cartItem !== undefined && cartItem.quantity > 0
                          ? "Added"
                          : "Add to cart"}
                      </button>
                    ) : (
                      <button disabled className="active">
                        Out of Stock
                      </button>
                    )}
                  </div>

                  <div className="shop-list-wishlist ml-10">
                    <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      disabled={wishlistItem !== undefined}
                      title={
                        wishlistItem !== undefined
                          ? "Added to wishlist"
                          : "Add to wishlist"
                      }
                      onClick={() => addToWishlist(product, addToast)}
                    >
                      <i className="pe-7s-like" />
                    </button>
                  </div>
                  <div className="shop-list-compare ml-10">
                    <button
                      className={compareItem !== undefined ? "active" : ""}
                      disabled={compareItem !== undefined}
                      title={
                        compareItem !== undefined
                          ? "Added to compare"
                          : "Add to compare"
                      }
                      onClick={() => addToCompare(product, addToast)}
                    >
                      <i className="pe-7s-shuffle" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ProductGridListSingle.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object,
  avalaibleColors: PropTypes.any,
  partnerName: PropTypes.string
};

export default ProductGridListSingle;
